// Anything exported from this file is importable by other in-browser modules.
// @ts-nocheck
import jwt_decode from "jwt-decode";
import { BehaviorSubject, min } from "rxjs";
export const str = "HEllo";
// export const web_url = "https://partners.digisevapay.in/";
export const web_url = window.location.href;


if (process.env.NODE_ENV === "development") { 
} else if (process.env.NODE_ENV === "production") { 
}
export const subuser = ['finance','support','on-borderding'];
export function refreshToken() {
  this.allowRefresh = false;
  // @ts-ignore
  const encUrl = AuthConfig.config.encodeUrl(
    "https://itpl.iserveu.tech/logintokenrefresh.json"
  );
  // @ts-ignore
  this.http
    .post(encUrl, {})
    .pipe(
      // @ts-ignore
      finalize(() => {
        this.allowRefresh = true;
      })
    )
    .subscribe(
      (/** @type {{ token: string; }} */ res) => {
        // Replace Token
        sessionStorage.setItem("CORE_SESSION", res.token);
        // this.appService.autoLogOut(); // Refresh Logout Timer.
      },
      // @ts-ignore
      (/** @type {any} */ err) => { }
    );
}
// @ts-ignore
/**
 * @param {any} value
 */
export function sharedData(value) {
  let walletbalance = new BehaviorSubject(false);
  if (value == true) {
    walletbalance.next(true);
  }
}
/**
 * @param {number} numbervalue
 * @param {boolean} booleanvalue
 */
// @ts-ignore
export let collapseValue = new BehaviorSubject(17);
export let collapseBooleanValue = new BehaviorSubject(0);
export function collapseBtn(numbervalue, booleanvalue) {
  collapseValue.next(numbervalue);
  collapseBooleanValue.next(booleanvalue);
}
/**
 * @param {number} numbervalue1
 * @param {boolean} booleanvalue1
 */
export let collapseValue1 = new BehaviorSubject(17);
export let collapseBooleanValue1 = new BehaviorSubject(0);
export function onlyForLeftNav(numbervalue1, booleanvalue1) {
  collapseValue1.next(numbervalue1);
  collapseBooleanValue1.next(booleanvalue1);
}
export let kycUpdateStatus = new BehaviorSubject();
export function statusUpdateFunction(randomvalue) {
  kycUpdateStatus.next(randomvalue);
}
let testBal;
export const shareBal = new BehaviorSubject("");
export function updateWallet(value) {
  testBal = value;
  shareBal.next(testBal);
}

export const newBal1 = new BehaviorSubject(0);
export async function getwallet1_balance() {
  let response = await fetch(
    "https://apiprod.iserveu.tech/cprod/wallet/common/wallet1fetchbalance",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionStorage.getItem("CORE_SESSION")}`,
      },
    }
  );
  let data = await response.json();
  if (data.status == 0) {
    newBal1.next(data.response.balance);
  } else {
    newBal1.next(0);
  }
}

export const newBal2 = new BehaviorSubject(0);
export async function getwallet2_balance() {
  let response1 = await fetch(
    "https://apiprod.iserveu.tech/cprod/wallet/common/wallet2fetchbalance",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionStorage.getItem("CORE_SESSION")}`,
      },
    }
  );
  let data = await response1.json();
  if (data.status == 0) {
    newBal2.next(data.data.balance);
  } else {
    newBal2.next(0);
  }
}

export const newBal3 = new BehaviorSubject(0);
export async function getwallet3_balance() {
  let response2 = await fetch(
    "https://apiprod.iserveu.tech/cprod/wallet/common/api/wallet1fetchbalance",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionStorage.getItem("CORE_SESSION")}`,
      },
    }
  );
  let data = await response2.json();
  if (data.status == 0) {
    newBal3.next(data.response.balance);
  } else {
    newBal3.next(0);
  }
}

export const mapArr = ["https://partners.mydigitalpayment.com/aeps"];
export const aadharRegArr = ["https://partners.mydigitalpayment.com/aadhar","https://localhost:4200/aadhar"];

export const Base_url = {
  auth_base: "https://oauth2-auth-server-common-admin-prod.iserveu.tech/",
  user_base: "https://user-registration-common-admin-prod.iserveu.tech/",
  transaction_base:
    "https://transactional-module-common-admin-prod.iserveu.tech/",
  matm_mapping: "https://apiprod.iserveu.tech/cprod/common_matm_map", //old url
  // matm_mapping: "https://apiprod.iserveu.tech/cprodV2/common_matm_map", //new url
  prod_url: "https://apiprod.iserveu.tech/cprod/",
  // ghi_stage: "https://apidev.iserveu.online/stagingauth/livLong/",
  ghi_prod: "https://apiprod.iserveu.tech/cprodV2/livLong/",
  hospicash_stage: "https://apidev.iserveu.online/stagingauth/hospicash/",
  notify_sub:
    // "https://fcmtokensubscription-vn3k2k7q7q-uc.a.run.app/subscribeToTopicByUserid", //old url
    "https://apiprod.iserveu.tech/cprodV2/ca/token/subscribeToTopicByUserid",
  notify_unsub:
    // "https://fcmtokensubscription-vn3k2k7q7q-uc.a.run.app/unsubscribeToTopicByUserid", //old url
    "https://apiprod.iserveu.tech/cprodV2/ca/token/unsubscribeToTopicByUserid",
};
let logOutTimer = undefined;
export const refershtime = 15;
export function autoLogOut() {

  const tokenData = jwt_decode(sessionStorage.getItem("CORE_SESSION") || "{}");
  const startDate = new Date();
  const expDate = new Date(tokenData.exp * 1000);
  const session = Math.ceil(expDate - startDate);
  const mins = Math.floor(session / 1000 / 60); 
  if (logOutTimer) {
    clearTimeout(logOutTimer);
  }
  logOutTimer = setTimeout(() => { 
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/login";
  }, session);
}
export async function refreshapi() {
  try {
    let params = new URLSearchParams();
    params.set("grant_type", "refresh_token");
    params.set("refresh_token", sessionStorage.getItem("refresh_token") || "");
    let response1 = await fetch(
      "https://oauth2-auth-server-common-admin-prod.iserveu.tech/oauth/token?" +
      params,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            `Basic ` +
            btoa("common-admins-oauth2-client:common-admins-oauth-password"),
        },
      }
    ).catch((err) => {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/login";
    });
    let data = await response1.json();
    sessionStorage.setItem("access_token", data.access_token);
    sessionStorage.setItem("CORE_SESSION", data.access_token);
    sessionStorage.setItem("refresh_token", data.refresh_token);
    autoLogOut();
  } catch (err) {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/login";
  }
}
let intervaltime = undefined;
export let notificationData = new BehaviorSubject({});
export async function notification() {
  if (intervaltime == undefined) {
    notificationfun();
  }

  intervaltime = setInterval(async () => {
    notificationfun();
  }, 30000);
}
export function clearnotifyinterval() {
  if (intervaltime) {
    notificationData.next({});
    clearTimeout(intervaltime);
  }
}
export async function notificationfun() {
  try {
    const reqBody = {
      user_name: JSON.parse(sessionStorage.getItem("dashboardData")).userInfo
        .userName,
      product_name: "Global",
      limit: 30,
      page: 1,
      type: "Transactional",
    };
    let response = await fetch(
      // "https://apidev.iserveu.online/stagingauth/common/fetch_notification", //old url
      "https://apiprod.iserveu.tech/cprodV2/ca/notification/fetch_notification",
      {
        method: "POST",
        // body:reqBody, 
        body: JSON.stringify({
          user_name: JSON.parse(sessionStorage.getItem("dashboardData"))
            .userInfo.userName,
          product_name: "Global",
          limit: 30,
          page: 1,
          type: "Transactional",
        }),
        headers: {
          "content-type": "application/json",
          Authorization: `${sessionStorage.getItem("CORE_SESSION")}`,
        },
      }
    ).catch((err) => { 
      if(validate_exp()){
        sessionStorage.clear();
        localStorage.clear();
        sessionStorage.setItem('err_fetch_noti', 'yes');
        clearnotifyinterval();
        window.location.href = "/login"; 
      }
    });
    let data = await response.json(); 
    if (data.status === 0) {
      notificationData.next(data);
    } else {
      notificationData.next({})
    }
  } catch (error) { 
    if(validate_exp()){
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.setItem('err_fetch_noti', 'yes');
      window.location.href = "/login"; 
    }
  }
}

export const adminConfig = [
  { adminName: "technewadmin", canEnableUser: "false" },
  { adminName: "babloow84", canEnableUser: "false" },
  { adminName: "stratonw522", canEnableUser: "false" },
  { adminName: "arthpayw494", canEnableUser: "false" },
  { adminName: "demoisu", canEnableUser: "false" },
  { adminName: "paysallwlw523", canEnableUser: "false" },
  { adminName: "janpaisaw24", canEnableUser: "false" },
  { adminName: "acultwl527", canEnableUser: "false" },
  { adminName: "meharw529", canEnableUser: "false" },
  { adminName: "subkw528", canEnableUser: "false" },
  { adminName: "synergyw532", canEnableUser: "false" },
];
export let kycStatus = new BehaviorSubject();
export function fetchUserData() {
  const authorizationToken = sessionStorage.getItem("CORE_SESSION");
  const apiUrl =
    "https://signup-module-common-admin-uat-twdwtabx5q-el.a.run.app/user/dashboard";
  const headers = {
    Authorization: `${authorizationToken}`,
  };
  fetch(apiUrl, {
    headers: headers,
  })
    .then((response) => {
      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Parse the response JSON data
      return response.json();
    })
    .then((data) => {
      kycStatus.next(data.userInfo.userProfile.kycActiveStatus);
      sessionStorage.setItem("dashboardData", JSON.stringify(data));
    })
    .catch((error) => { 
    });

}
const allAdmin_details = {
  arthpaytech: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/storeimg.png",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/arthpaylogo.png",
    commonOnboarding: true,
    showApproval:true,
    dashboard: false,
    talk2us: true,
    arthpayContent: true,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/root-assets/ARTH%20PAY_logo.png",
    tabName: "ArthPay",
    colorIndex: 0
  },
  mydigitalpayment: {
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/tonav_babloo_logo.png",
    commonOnboarding: true,
    showApproval:true,
    dashboard: true,
    talk2us: false,
    colorIndex: 0,
    favUrl:"https://storage.googleapis.com/micro-services-frontend/dist/assets/favicon.gif",
    tabName: "Common Admin",
  },
  'common-admin': {
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/tonav_babloo_logo.png",
    commonOnboarding: true,
    showApproval:true,
    dashboard: true,
    talk2us: false,
    colorIndex: 0,
    favUrl:"https://storage.googleapis.com/micro-services-frontend/dist/assets/favicon.gif",
    tabName: "Common Admin",
  },
  digitalsewa: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/arthpaylogo.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:"https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/arthpaylogo.png",
    tabName: "Digital Sewa",
    colorIndex: 0
  },
  stratonfinserv: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/start-on-logo.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/start-on-logo.png",
    tabName: "Starton Finserv",
    colorIndex: 0
  },
  biharipay: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/bihari-pay.jpg",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/bihari-pay.jpg",
    tabName: "Bihari Pay",
    colorIndex: 0
  },
  paysallbanking: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/Paysall%20Banking%20Final%20Logo%20-%20Board%20Mytrip.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/Paysall%20Banking%20Final%20Logo%20-%20Board%20Mytrip.png",
    tabName: "Pays All Banking",
    colorIndex: 0
  },
  bbpay: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/BBT%20-%20shiju%20BB.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/BBT%20-%20shiju%20BB.png",
    tabName: "BBPAY",
    colorIndex: 1
  },
  janpaisa: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/jan_paisa.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/top-nav-assets/jan_paisa.png",
    tabName: "Jan Paisa",
    colorIndex: 0
  },
  acultpay: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Acultpay%20-%20Praveen%20Kumar%20R.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Acultpay%20-%20Praveen%20Kumar%20R.png",
    tabName: "Acult Pay",
    colorIndex: 1
  },
  cooppay: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/coopay.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/coopay.png",
    tabName: "COOPPAY MERCHANT",
    privacyPolicy: true,
    colorIndex: 0
  },
  subk: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Sub-K%20logo.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Sub-K%20logo.png",
    tabName: "Subk Pay",
    colorIndex: 1
  },
  somriddhidigital: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/samridhi.jpg",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/samridhi.jpg",
    tabName: "Somriddhi Digital",
    colorIndex: 1
  },
  localhost: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/storeimg.png",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/arthpaylogo.png",
    commonOnboarding: true,
    showApproval:true,
    dashboard: true,
    talk2us: true,
    arthpayContent: true,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/favicon.gif",
    tabName: "Common Admin",
    colorIndex: 0
  },
  payufi: {
    loginImgUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
    topnavLogo:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payUFI.png",
    commonOnboarding: false,
    showApproval:false,
    dashboard: true,
    talk2us: false,
    favUrl:
      "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/payUFI.png",
    tabName: "PayUFI",
    colorIndex: 0
  },
  digisevapay:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Digi%20Seva%20Logo.png",
  commonOnboarding: true,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/Digi%20Seva%20Logo.png",
  tabName: "digisevapay",
  colorIndex: 0
  },
  easycashpoint:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/EasyPointCash.png",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/EasyPointCash.png",
  tabName: "easycashpoint",
  colorIndex: 0
  },
  'paym.co':{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/PAY%20M.png",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/PAY%20M.png",
  tabName: "Paym Tech",
  colorIndex: 0
  },
  acashpay:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/acashpay%20PNG%20-%20Toshani%20Management.png",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/acashpay%20PNG%20-%20Toshani%20Management.png",
  tabName: "acashpay",
  colorIndex: 0
  },
  wizaker:{
    loginImgUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/start-on.svg",
  topnavLogo:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/WK%20NEW%20LOGO.jpg",
  commonOnboarding: false,
  showApproval:false,
  dashboard: true,
  talk2us: false,
  favUrl:
    "https://storage.googleapis.com/micro-services-frontend/dist/assets/login-assets/WK%20NEW%20LOGO.jpg",
  tabName: "wizaker",
  colorIndex: 0
  }
};
export let details;
export let adminName;
for (var index in allAdmin_details) {
  if (web_url.includes(index)) {
    details = allAdmin_details[index]; 
    adminName = index;
  }
} 
function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
export function checkAdmin_insurance() {
  let adminName2 = JSON.parse(sessionStorage.getItem("dashboardData"))?.userInfo
    .adminName;
  if (
    adminName2 === "babloow84" ||
    adminName2 === "technewadmin" ||
    adminName2 === "sdplw530"
  ) {
    return true;
  } else {
    return false;
  }
}
export function validate_exp() {
  let data = sessionStorage.getItem("CORE_SESSION");
  let exp = parseJwt(data); 
  const timestamp = exp.exp;
  const today = new Date();
  const todaytimestamp = today.getTime();
  const timestampInSeconds = Math.floor(todaytimestamp / 1000);
  if(timestampInSeconds<=timestamp){
    return false
  } else {
    return true
  }
  // const date = new Date(timestamp * 1000); // Convert to milliseconds by multiplying by 1000

  // // Extract the components of the date and time
  // const year = date.getFullYear();
  // const month = date.getMonth() + 1; // Month is zero-based, so add 1
  // const day = date.getDate();
  // const hours = date.getHours();
  // const minutes = date.getMinutes();
  // const seconds = date.getSeconds();

  // // Create a human-readable date and time string
  // const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
 

}
export function checkAdmin_Hospicash_insurance() {
  let adminName3 = JSON.parse(sessionStorage.getItem("dashboardData"))?.userInfo
    .adminName;
  if (
    // adminName2 === "babloow84" ||
    adminName3 === "technewadmin"
    // adminName2 === "sdplw530"
  ) {
    return true;
  } else {
    return false;
  }
}

